import Axios from 'axios';
import ColorHelper from "../helper/ColorHelper";
import AuthProvider from "../provider/AuthProvider";

export default class MeasureService {

    static defaultMeasureTypes = {
        measure: [
            {key: 'eeg', label: 'EEG'},
            {key: 'balance', label: '균형상태'},
            {key: 'meditationTest', label: '뇌기능 의식패턴'},
            {key: 'measureBalance', label: '뇌특성분석'},
            {key: 'measureBandTest', label: '항상성 자기조절'},
        ],
        life: [
            {key: 'emotion', label: '감정측정'},
            {key: 'health', label: '운동측정'},
            {key: 'life', label: '생활습관검사'},
            {key: 'recognition', label: '인지기능 평가'},
        ],
        test: [
            { key: 'cognitiveTest1', label: '인지변별력'},
            { key: 'cognitiveTest2', label: '메타인지'},
            { key: 'memoryTest2_8', label: '주의력'},
            { key: 'memoryTest2_9', label: '집중력'},
            { key: 'memoryTest3', label: '주의 & 집중'},
            { key: 'memoryTest1', label: '기억력 (좌뇌)'},
            { key: 'memoryTest1_3', label: '기억력 (우뇌)'},
            { key: 'memoryTest1_4', label: '기억력 (균형)'},
            { key: 'memoryTest2_5', label: '장기기억력'},
            { key: 'memoryTest2_6', label: '공감력'},
            { key: 'memoryTest2_7', label: '사고력'},

        ]
    }
    
    /**
     * return new log ID
     * @param prescriptionID
     * @param hzType
     * @param measureTypeCode
     * @param measureCode
     * @param measureTime
     * @returns {Promise<null|*|number>}
     */
    static async newMeasureLog({ prescriptionID, hzType, measureTypeCode = '', measureCode = '', measureTime = 0, measureType }){
        try {
            const result = await Axios.post('/api/measure/log',{
                prescriptionID,
                hzType,
                measureTypeCode, 
                measureCode, 
                measureTime,
                measureType
            })
            
            return result.data.logID
        } catch(e){}
        
        return 0
    }

    /**
     * 모든 데이터를 가져옵니다. (fft, raw, band, map)
     */
    static async getMeasureAll(){
        try {
            const fft = Axios.get('/api/measure/fft')
            const band = Axios.get('/api/measure/band')
            const map = Axios.get('/api/measure/map')
            const raw = Axios.get('/api/measure/raw')

            const result = await Axios.all([fft,band,map,raw])

            if(204 === result.status){
                return result.data
            }

        }catch{}

        return []
    }
    
    /**
     * 설정된 운동관련 설정의 hz를 가져옵니다.
     * @param hzType
     * @param keyId
     * @param callback
     * @returns {Promise<void>}
     */
    static async getComputeHz(hzType, keyId = 'last', callback){
        let computeHz
        try {
            const result = await Axios.get('/api/computeHz/' + keyId,{
                params: {
                    hzType
                }
            })
            computeHz = result.data.band;
        } catch(e){}

        callback(computeHz);
    }

    /**
     * 등록되어있는 hzType의 meausreCode의 마지막 설정값을 가져옵니다.
     * @param hzType
     * @param measureTypeCode
     * @param measureCode
     * @param time
     * @returns {Promise<[]>}
     */
    static async getComputeHzLastWithCode(hzType = 'default', measureTypeCode = '10', measureCode = 'C', time){
        let computeHz
        try {
            const result = await Axios.get(`/api/computeHz/code/${measureCode}/last`,{
                params: {
                    hzType,
                    measureTypeCode,
                    time
                }
            })
            computeHz = result.data;
        } catch(e){}
        return computeHz
    }

    /**
     * 프로그램별 , measuerCode별 hz값을 변경합니다.
     * @param hzType
     * @param measureTypeCode
     * @param measureCode
     * @param band
     * @param time
     * @param stepCount
     * @returns {Promise<void>}
     */
    static async updateComputeHzWithCode(hzType, measureTypeCode, measureCode, band, time = 0, stepCount = 1){

        try {
            await Axios.put('/api/computeHz/code', {
                hzType,
                measureTypeCode,
                measureCode,
                band,
                time,
                stepCount
            })
        } catch(e){}
    }

    /**
     * 명상에 설정된 초에 코드값의 데이터를 삭제합니다.
     * @param hzType
     * @param measureTypeCode
     * @param measureCode
     * @param time
     * @returns {Promise<void>}
     */
    static async deleteComputeWithCode(hzType, measureTypeCode, measureCode, time){
        try {
            await Axios.delete('/api/computeHz/code', {
                data: {
                    hzType,
                    measureTypeCode,
                    measureCode,
                    time
                }
            })
        } catch(e){}
    }

    /**
     * 
     * @param measureTypeCode
     * @param measureCode
     * @returns {Promise<void>}
     */
    static async getMeditationTimePoint(measureTypeCode, measureCode)  {
        try {
            const result = await Axios.get('/api/computeHz/meditation-point', {
                params: {
                    measureTypeCode,
                    measureCode
                }
            })
            
            return result.data
        } catch(e){}
        
        return null
    }

    /**
     * 설정정보 
     */
    static async getConfig(){
        try {
            const res = await Axios.get('/api/config')
            return {
                success: true ,
                data: res.data
            }
            
        } catch(e){
            return {
                success: false,
                message: '권한이 없습니다.'
            }
        }
    }

    /**
     * 설정정보
     */
    static async getNoise(deviceID){
        let res
        if('common' === deviceID){
            try {
                res = await this.getConfig()
                const noiseData = {
                    ch1: [],
                    ch2: []
                }
                res.data.config.map(value => {
                    if('NOISE_CH1' === value.CFG_TYPE){
                        noiseData.ch1 = value.VALUE.split(',')
                    }

                    if('NOISE_CH2' === value.CFG_TYPE){
                        noiseData.ch2 = value.VALUE.split(',')
                    }
                    return value
                });
                return noiseData
            } catch (e) {
                console.log(e);
            }
            

        } else {
            try {
                res = await Axios.get('/api/noise/' + deviceID)
                return res.data
            } catch (e){}
        }

        return null
    }

    /**
     * 노이즈 업데이트
     * @param useNoiseFilter
     * @param noise
     * @param noiseCut
     * @param rawAmpPercent
     * @param highPass
     * @param lowPass
     */
    static async updateConfig(useNoiseFilter, noise, noiseCut, rawAmpPercent, highPass, lowPass){
        try {
            await Axios.put('/api/config', {
                useNoiseFilter,
                noise: noise.join(','),
                noiseCut: noiseCut.join(','),
                rawAmpPercent,
                highPass,
                lowPass
            })
        } catch(e){}
    }

    static async updateNoise(deviceID, noise){
        try {
            await Axios.put(`/api/noise/${deviceID}`, {
                noise
            })
        } catch(e){}
    }
    
    static async updateConfigEach(type, value){
        try {
            await Axios.put('/api/config/each', {
                type,
                value
            })
        } catch(e){}
    }

    static async clearNoise(deviceID){
        try {
            await Axios.delete(`/api/noise/${deviceID}`)
        } catch(e){}
    }

    /**
     * 측정기록을 가져옵니다
     * @returns {Promise<any>}
     */
    static async getMeasureLog(date, filterTypes=[]){
        try{
            const res = await Axios.get(`/api/log/measure/${date}?filterTypes=${filterTypes.join(',')}`)
            return res.data.logs
        } catch(e){}
    }

  static async getMeasureLogWithProfileID(date, profileID){
    try{
      const res = await Axios.get(`/api/log/measure/${date}?profileID=${profileID}`)
      return res.data.logs
    } catch(e){}
  }
  
    static async getMeasureLogChart(start, end, filterTypes=[]){
        try{
            const offset = new Date().getTimezoneOffset();
            const offsetHours = -offset / 60;
            const res = await Axios.get(`/api/log/measure/chart?timeOffset=${offsetHours}&start=${start}&end=${end}&filterTypes=${filterTypes.join(',')}`)
            return res.data.scores
        } catch(e){}
    }

    static async getMeasureLogByID(id){
      try{
        const res = await Axios.get(`/api/log/id/${id}`)
        return res.data
      } catch(e){}
    }
    
    static async getMeasureCount({profileID, measureGroup = 'measure', centerID = null}){
        try {
            const measureTypesCount = this.getDefaultMeasureTypesCount({measureGroup})
            let find = []
            
            if('all' === measureGroup){
                Object.keys(measureTypesCount).map((key) => {
                    const count = measureTypesCount[key]
                    count.map((type) => {
                        find.push(type)
                        return type
                    })
                    return key
                })
            } else {
                find = measureTypesCount
            }

            const result = await Axios.get('/api/measure/count',{
                params: {
                    types: find.map(type => type.key).join(','),
                    profileSelectorID: AuthProvider.getProfileSelectorID(),
                    profileID,
                    centerID,
                }
            })
            
            if( 'all'  === measureGroup) {
                let newMeasureTypeCount = {}
                Object.keys(measureTypesCount).map((key) => {
                    const typeCount = measureTypesCount[key]

                    newMeasureTypeCount[key] = typeCount.map((type) => {
                        const count = (result.data || []).find(count => count.measureType === type.key)
                        return {
                            ...type,
                            count: count ? count.measureCount : 0,
                            centerCount: count ? count.centerCount : 0,
                            centerID: count ? count.centerID: null
                        }
                    })
                    
                    return key
                })
                
                return newMeasureTypeCount
            } else {
                return measureTypesCount.map((type) => {
                    const count = (result.data || []).find(count => count.measureType === type.key)
                    return {
                        ...type,
                        count: count ? count.measureCount : 0,
                        centerCount: count ? count.centerCount : 0,
                        centerID: count ? count.centerID: null
                    }
                })
            }
        } catch(e){}

        return null
    }
    
    static getDefaultMeasureTypesCount({measureGroup = 'measure', checkedTypes = []}){
        if('all' === measureGroup) return this.defaultMeasureTypes
        
        const values = this.defaultMeasureTypes[measureGroup]
        return values.map((group) => {
            return {
                ...group,
                checked: -1 < (checkedTypes || []).indexOf(group.key)
            }
        })
    }

    static async updateMeasureTypeCount({profileID, centerID, measureType, count}){
        try {
            await Axios.put('/api/measure/count',{
                measureType,
                profileID,
                centerID,
                count
            })
            
            return true
        } catch(e){
        }

        return false
    }

    static async useMeasureType({profileID, measureType}){
        try {
            await Axios.put('/api/measure/use',{
                measureType,
                profileID
            })

            return true
        } catch(e){
        }

        return false
    }
    
    static getMemoryItems(type = 'level1') {
        if('level2' === type){
            return [
                {index: 33, name: '옷'},
                {index: 23, name: '거북이'},
                {index: 21, name: '오리'},
                {index: 6, name: '딸기'},
                {index: 48, name: '안경'},
                {index: 36, name: '자동차'},
                {index: 26, name: '수박'},
                {index: 47, name: '아이스크림'},
                {index: 18, name: '사슴'},
                {index: 40, name: '장미'},
                {index: 55, name: '토끼'},
                {index: 50, name: '우유'},
                {index: 41, name: '강아지'},
                {index: 14, name: '호랑이'},
                {index: 5, name: '오렌지'},
                {index: 39, name: '고양이'},
                {index: 12, name: '포도'},
                {index: 16, name: '코끼리'},
                {index: 17, name: '옥수수'},
                {index: 19, name: '다람쥐'},
                {index: 7, name: '감자'},
                {index: 20, name: '원숭이'},
                {index: 8, name: '가지'},
                {index: 3, name: '토마토'},
                {index: 24, name: '기린'},
                {index: 37, name: '나무'},
                {index: 60, name: '팽귄'},
                {index: 4, name: '사과'},
                {index: 34, name: '자전거'},
                // {index: 2, name: '당근'},
                {index: 32, name: '티슈'},
                {index: 31, name: '컵'},
                {index: 59, name: '병아리'},
                // {index: 11, name: '복숭아'},
                {index: 35, name: '로봇'},
                {index: 44, name: '립스틱'},
                {index: 25, name: '양배추'},
                {index: 57, name: '목련'},
                {index: 45, name: '드라이'},
                {index: 13, name: '사자'},
                {index: 61, name: '축구공'},
                {index: 62, name: '풍선'},
            ]
        }
    
        return [
            {index: 0, name: '베터리'},
            {index: 1, name: '피망'},
            {index: 2, name: '당근'},
            {index: 3, name: '토마토'},
            {index: 4, name: '사과'},
            {index: 5, name: '오렌지'},
            {index: 6, name: '딸기'},
            {index: 7, name: '감자'},
            {index: 8, name: '가지'},
            {index: 9, name: '무'},
            {index: 10, name: '멜론'},
            // {index: 11, name: '복숭아'},
            {index: 12, name: '포도'},
            {index: 13, name: '사자'},
            {index: 14, name: '호랑이'},
            {index: 15, name: '하마'},
            {index: 16, name: '코끼리'},
            {index: 17, name: '옥수수'},
            {index: 18, name: '사슴'},
            {index: 19, name: '다람쥐'},
            {index: 20, name: '원숭이'},
            {index: 21, name: '오리'},
            {index: 22, name: '악어'},
            {index: 23, name: '거북이'},
            {index: 24, name: '기린'},
            {index: 25, name: '양배추'},
            {index: 26, name: '수박'},
            {index: 27, name: '배추'},
            {index: 28, name: '접시'},
            // {index: 29, name: '그릇'},
            {index: 29, name: '운동화'},
            {index: 30, name: '스푼'},
            {index: 31, name: '컵'},
            // {index: 32, name: '티슈'},
            {index: 33, name: '옷'},
            {index: 34, name: '자전거'},
            {index: 35, name: '로봇'},
            {index: 36, name: '자동차'},
            {index: 37, name: '나무'},
            {index: 38, name: '계곡'},
            {index: 39, name: '고양이'},
            {index: 40, name: '장미'},
            {index: 41, name: '강아지'},
            {index: 42, name: '산'},
            {index: 43, name: '바다'},
            {index: 44, name: '립스틱'},
            {index: 45, name: '드라이'},
            {index: 46, name: '구름'},
            {index: 47, name: '아이스크림'},
            {index: 48, name: '안경 '},
            {index: 49, name: '밥솥'},
            {index: 50, name: '우유'},
            {index: 51, name: '꿩'},
            // {index: 52, name: '노트'},
            {index: 52, name: '모자'},
            
            {index: 53, name: '노트북'},
            {index: 54, name: '닭'},
            // {index: 55, name: '만년필'},
            {index: 55, name: '토끼'},
            {index: 56, name: '모니터'},
            {index: 57, name: '목련'},
            {index: 58, name: '벛꽃'},
            {index: 59, name: '병아리'},
            {index: 60, name: '팽귄'},
            // {index: 60, name: '볼펜'},
            {index: 61, name: '축구공'},
            {index: 62, name: '풍선'},
        ]
    }
    
    static getPreLevel(type = 'EEG'){
        const preColors = ColorHelper.getPreLevelColor(type, 10)
        const { backgroundColors , colors } = preColors

        // backgroundColors.push('#EAE9EF')
        // colors.push('#999')
        
        let index = 0;
        return 'EEG' === type
        ?  [
            {name: '48 이상', level: 0, levelText: '위급관리 7단계' , levelName: '1급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '44 - 48 이하', level: 1, levelText: '위급관리 6단계', levelName: '2급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '40 - 44 이하', level: 2, levelText: '특별관리 5단계', levelName: '3급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '36 - 40 이하', level: 3, levelText: '특별관리 4단계', levelName: '4급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '32 - 36 이하', level: 4, levelText: '주의관리 3단계', levelName: '5급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '28 - 32 이하', level: 5, levelText: '주의관리 2단계', levelName: '6급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '24 - 28 이하', level: 6, levelText: '주의관리 1단계', levelName: '7급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '20 - 24 이하', level: 7, levelText: '주의', levelName: '8급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '16 - 20 이하', level: 8, levelText: '경계', levelName: '9급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '12 - 16 이하', level: 9, levelText: '유지', levelName: '10급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '8 - 12 이하', level: 10, levelText: '정상', levelName: '0급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '4 - 8 이하', level: 11, levelText: '약저', levelName: '-1급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '4 이하', level: 12, levelText: '극저', levelName: '-2급', backgroundColor: backgroundColors[index], color: colors[index++]},
        ]
        : [
            {name: '95%이상',     level: 1,   levelText: '위급관리 5단계' , levelName: '1급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '90%이상~95%', level: 2,   levelText: '위급관리 4단계', levelName: '2급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '85%이상~90%', level: 3,   levelText: '특별관리 3단계', levelName: '3급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '80%이상~85%', level: 4,   levelText: '특별관리 2단계', levelName: '4급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '75%이상~80%', level: 5,   levelText: '!주의관리 1단계', levelName: '5급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '70%이상~75%', level: 6,   levelText: '!주의', levelName: '6급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '65%이상~70%', level: 7,   levelText: '경계', levelName: '7급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '60%이상~65%', level: 8,   levelText: '유의', levelName: '8급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '55%이상~60%', level: 9,   levelText: '유지', levelName: '9급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '50%이상~55%', level: 10,  levelText: '정상', levelName: '0급', backgroundColor: backgroundColors[index], color: colors[index++]},
            {name: '45%이상~50%', level: 11,  levelText: '유지', levelName: '9급', backgroundColor: '#fff', color: colors[index]},
            {name: '40%이상~45%', level: 12,  levelText: '유의', levelName: '8급', backgroundColor: '#fff', color: colors[index]},
            {name: '35%이상~40%', level: 13,  levelText: '경계', levelName: '7급', backgroundColor: '#fff', color: colors[index]},
            {name: '30%이상~35%', level: 14,  levelText: '!주의', levelName: '6급', backgroundColor: '#fff', color: colors[index]},
            {name: '25%이상~30%', level: 15,  levelText: '!주의관리 1단계', levelName: '5급', backgroundColor: '#fff', color: colors[index]},
            {name: '20%이상~25%', level: 16,  levelText: '특별관리 2단계', levelName: '4급', backgroundColor: '#fff', color: colors[index]},
            {name: '15%이상~20%', level: 17,  levelText: '특별관리 3단계', levelName: '3급', backgroundColor: '#fff', color: colors[index]},
            {name: '10%이상~15%', level: 18,  levelText: '위급관리 4단계', levelName: '2급', backgroundColor: '#fff', color: colors[index]},
            {name: '5%이상~10%', level: 19,   levelText: '위급관리 5단계' , levelName: '1급', backgroundColor: backgroundColors[0], color: colors[index]},
                
        ];
    }

    /**
     * 좌우뇌 비율로 등급처리
     * @returns {number}
     * @param percent
     */
    static makeDeltaToPreLevelBalance(percent){

        let index = 10
        if(percent >= 95 )  index = 1
        if(percent >= 90 && percent < 95)  index = 2
        if(percent >= 85 && percent < 90)  index = 3 
        if(percent >= 80 && percent < 85)  index = 4 
        if(percent >= 75 && percent < 80)  index = 5 
        if(percent >= 70 && percent < 75)  index = 6 
        if(percent >= 65 && percent < 70)  index = 7 
        if(percent >= 60 && percent < 65)  index = 8 
        if(percent >= 55 && percent < 60)  index = 9 
        if(percent >= 50 && percent < 55)  index = 10 
        if(percent >= 45 && percent < 50)  index = 11 
        if(percent >= 40 && percent < 45)  index = 12 
        if(percent >= 35 && percent < 40)  index = 13 
        if(percent >= 30 && percent < 35)  index = 14 
        if(percent >= 25 && percent < 30)  index = 15 
        if(percent >= 20 && percent < 25)  index = 16 
        if(percent >= 15 && percent < 20)  index = 17 
        if(percent >= 10 && percent < 15)  index = 18 
        if(percent >= 5 && percent < 10)  index = 19
        
        return index
        // let preLevel = 10 // 정상
        // if(45 <= diffPercent)  preLevel = 1
        // else if(40 <= diffPercent && diffPercent < 45) preLevel = 2
        // else if(35 <= diffPercent && diffPercent < 40) preLevel = 3
        // else if(30 <= diffPercent && diffPercent < 35) preLevel = 4
        // else if(25 <= diffPercent && diffPercent < 30) preLevel = 5
        // else if(20 <= diffPercent && diffPercent < 25) preLevel = 6
        // else if(15 <= diffPercent && diffPercent < 20) preLevel = 7
        // else if(10 <= diffPercent && diffPercent < 15) preLevel = 8
        // else if(5 <= diffPercent && diffPercent < 10) preLevel = 9
        // else if(diffPercent < 5) preLevel = 10
        // return preLevel
    }

    /**
     * EEG 비율로 등급처리
     * @param deltaAvg
     * @returns {number}
     */
    static makeDeltaToPreLevelEEG(deltaAvg){
    
        let preLevel = 2
        if(48 < deltaAvg)  preLevel = 0
        else if(44 <= deltaAvg && deltaAvg < 48) preLevel = 1
        else if(40 <= deltaAvg && deltaAvg < 44) preLevel = 2
        else if(36 <= deltaAvg && deltaAvg < 40) preLevel = 3
        else if(32 <= deltaAvg && deltaAvg < 36) preLevel = 4
        else if(28 <= deltaAvg && deltaAvg < 32) preLevel = 5
        else if(24 <= deltaAvg && deltaAvg < 28) preLevel = 6
        else if(20 <= deltaAvg && deltaAvg < 24) preLevel = 7
        else if(16 <= deltaAvg && deltaAvg < 20) preLevel = 8
        else if(12 <= deltaAvg && deltaAvg < 16) preLevel = 9
        else if(8 <= deltaAvg && deltaAvg < 12) preLevel = 10
        else if(4 <= deltaAvg && deltaAvg < 8) preLevel = 11
        else if(deltaAvg < 4) preLevel = 12
        return preLevel
        
    }

    /**
     * 밴드 측정 이름
     * @param bandType
     * @returns {string}
     */
    static getBandTypeName(bandType){
        if('rest' === bandType) {
            return  '안정 측정'
        } else if ('attemption' === bandType) {
            return  '주의력 측정'
        } else if ('concentration' === bandType) {
            return  '집중력 측정'
        } else if ('brainactive' === bandType) {
            return  '뇌활성 측정'
        } else if ('eeg' === bandType){
            return  'EEG'
        }
    }

    /**
     * 자각증상의 타입을 이름으로 변환합니다
     * @param surveyType
     * @returns {string}
     */
    static getSurveyTitle(surveyType){
        let title
        switch (surveyType){
            case 'emotion':
                title = '감정자각검사'
                break
            case 'health':
                title = '운동자각검사'
                break
            case 'life-cycle':
                title = '라이프사이클'
                break
            case 'life':
                title = '생활습관검사'
                break
            case 'recognition':
                title = '인지기능 평가'
                break;
            default:
                title = ''
        }
        
        return title
    }
    
    static getMeasurePageUrl(page){
        
        let path
        switch(page) {
            case 'survey':
            case 'survey-life':
                path = '/survey-year'
                break
            case 'measureTest':
                path = '/measureTest'
                break
            case 'measureTrain':
                path = '/measureTrain'
                break
            default:
                path = '/measureTest'
                break
        }
        return path
    }
}
